<template>
  <div class="vue-dynamique-class style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="4">
          <div class="title-header">
            Gestion des vues
          </div>
        </v-col>
        <v-col cols="2">
          <v-progress-circular
            class="ml-2"
            v-if="getLoadingVueDynamiqueAV"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="3">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="getErrorVueDynamiqueAV">
              <ul v-if="Array.isArray(getErrorVueDynamiqueAV)" class="mb-0">
                <li v-for="(e, index) in getErrorVueDynamiqueAV" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getErrorVueDynamiqueAV }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting pt-1 pb-1">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                v-if="checkPermission('GVTHGVTH')"
                @click="openModalAjouterVue"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter une vue</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="vue-body">
      <div class="block-vues">
        <div class="title">Vue</div>
        <div class="content-vue">
          <v-list class="list-category">
            <v-list-item-group>
              <v-list-item
                button
                v-for="vueDyn in getVueDynamiqueAV"
                :key="vueDyn.name"
                @click.prevent="handleClickVueDynamique(vueDyn)"
                class="custom-list-group custom-list-group-vue"
              >
                <v-list-item-icon
                  v-if="
                    selectedVueDynamique &&
                      selectedVueDynamique.name == vueDyn.name
                  "
                >
                  <font-awesome-icon class="icon-color-cdg" icon="check" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><span class="capitalize">
                      {{ vueDyn.name }}</span
                    ></v-list-item-title
                  >
                </v-list-item-content>
                <v-menu offset-y close-on-click>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      text
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="block-icon-reglement-fac mr-2"
                    >
                      <font-awesome-icon icon="ellipsis-h" />
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        @click.stop="handleUpdateVueDynamique(vueDyn)"
                        v-if="checkPermission('GVTHRV')"
                      >
                        <v-btn small class="block-icon-reglement-fac mr-2">
                          <font-awesome-icon class="icon" icon="pencil-alt"
                        /></v-btn>
                        Renommer
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click.stop="handleDeleteVueDynamique(vueDyn)"
                      v-if="checkPermission('GVTHSV')"
                    >
                      <v-list-item-title>
                        <v-btn small class="block-icon-reglement-fac mr-2">
                          <font-awesome-icon class="icon" icon="trash-alt"
                        /></v-btn>
                        Supprimer</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      @click.stop="handleDeleteVueDynamique(vueDyn, true)"
                      v-if="checkPermission('GVTHBDV')"
                    >
                      <v-list-item-title v-if="vueDyn.blocked == false">
                        <v-btn small class="block-icon-reglement-fac mr-2">
                          <font-awesome-icon class="icon" icon="eye"
                        /></v-btn>
                        Bloquer</v-list-item-title
                      >
                      <v-list-item-title v-else>
                        <v-btn small class="block-icon-reglement-fac mr-2">
                          <font-awesome-icon class="icon" icon="eye-slash"
                        /></v-btn>
                        Débloquer</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
      <div class="block-vue-details">
        <div class="custom-list-column-vu" v-if="selectedVueDynamique">
          <div class="custom-class-fields-av-update">
            <v-switch
              v-for="option in getListeFields"
              v-model="selectedVueDynamique.columns"
              :key="option.key"
              :value="option"
              @change="changeColumns"
              :disabled="!checkPermission('GVTHMC')"
              color="#704ad1"
              hide-details
              class="checked-vue"
            >
              <template v-slot:label>
                <span class="title-check-commercial-mail">
                  {{ option.label }}</span
                >
              </template>
            </v-switch>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="addvue"
      max-width="1500px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter une vue</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addvue')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addvue" lazy-validation>
            <v-text-field
              label="Nom de vue"
              v-model="vueToAdd.name"
              outlined
              required
              dense
              :persistent-placeholder="true"
              :rules="[v => !!v || 'Nom de vue est obligatoire']"
              class="msg-error mt-2"
              validate-on-blur
              color="#704ad1"
            ></v-text-field>
            <div class="switch-vue">
              <v-switch
                @change="changeAllCheckColumns"
                v-model="checkAll"
                label="Tous"
                color="#704ad1"
              >
              </v-switch>
            </div>
            <div class="custom-class-fields-av">
              <v-switch
                v-for="option in getListeFields"
                v-model="vueToAdd.selected"
                :key="option.label"
                :value="option"
                name="flavour-3a"
                :disabled="option.disabled"
                hide-details
                class="checked-vue"
                :label="option.label"
                color="#704ad1"
              >
              </v-switch>
            </div>
          </v-form>
        </v-card-text>
        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul
              v-if="Array.isArray(error)"
              class="mb-0"
              style="list-style-type: none"
            >
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="hendleSubmitVue"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addvue')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  Delete VueDynamique Modal -->
    <v-dialog
      v-model="VueDynamiqueDeleteModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">{{
            edit == true
              ? selectedVueDynamiqueToDelete.blocked == true
                ? 'Débloquer une vue'
                : 'Bloquer une Vue'
              : 'Supprimer une Vue'
          }}</v-label>

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('VueDynamiqueDeleteModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous certain de vouloir
              {{
                edit == true
                  ? selectedVueDynamiqueToDelete.blocked == true
                    ? 'Débloquer'
                    : 'bloquer'
                  : 'supprimer'
              }}
              la Vue
              <strong class="bold-text">
                {{
                  selectedVueDynamiqueToDelete
                    ? selectedVueDynamiqueToDelete.name
                    : ''
                }}
              </strong>
              ?
            </p>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="messageError">
            <div v-if="getErrorVueDynamiqueAV" class="error-msg">
              <ul v-if="Array.isArray(getErrorVueDynamiqueAV)">
                <li v-for="(e, index) in getErrorVueDynamiqueAV" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getErrorVueDynamiqueAV }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="getLoadingVueDynamiqueAV"
            :class="{ loader: getLoadingVueDynamiqueAV }"
            :disabled="getLoadingVueDynamiqueAV"
            @click.prevent.stop="handleModalSupprimerValider"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('VueDynamiqueDeleteModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Delete VueDynamique Modal -->
    <!-- Update Name VueDynamique Modal -->
    <v-dialog
      v-model="UpdateNameVueDynamiqueModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Renommer une vue</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('UpdateNameVueDynamiqueModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form
            ref="UpdateNameVueDynamiqueModal"
            lazy-validation
            v-if="selectedVueDynamiqueToUpdate"
          >
            <v-text-field
              label="Nom de vue"
              v-model="selectedVueDynamiqueToUpdate.name"
              outlined
              required
              dense
              :persistent-placeholder="true"
              :rules="[v => !!v || 'Nom de vue est obligatoire']"
              class="msg-error mt-2"
              validate-on-blur
              color="#704ad1"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <div class="messageError">
          <div v-if="getErrorVueDynamiqueAV" class="error-msg">
            <ul
              v-if="Array.isArray(getErrorVueDynamiqueAV)"
              class="mb-0"
              style="list-style-type: none"
            >
              <li v-for="(e, index) in getErrorVueDynamiqueAV" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorVueDynamiqueAV }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="submitUpdateNameVueDynamique"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn
            outlined
            @click.prevent="hideModal('UpdateNameVueDynamiqueModal')"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Update Name Modal-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import fieldsAnalyseValidationModal from '../../models/fieldsAnalyseValidationModal';
export default {
  name: 'Gestion-des-vues',
  data() {
    return {
      vueToAdd: {
        name: null,
        selected: []
      },
      loading: false,
      error: null,
      selectedVueDynamique: null,
      selectedVueDynamiqueToDelete: null,
      selectedVueDynamiqueToUpdate: null,
      edit: false,
      checkAll: true,
      addvue: false,
      UpdateNameVueDynamiqueModal: false,
      VueDynamiqueDeleteModal: false,
      getListeFields: fieldsAnalyseValidationModal.create().list
    };
  },
  methods: {
    ...mapActions([
      'AddVueDynamiqueAV',
      'getAllVueDynamiqueAV',
      'deleteVueDynamiqueAV',
      'blockVueDynamiqueAV',
      'updateNameVueDynamiqueAV'
    ]),
    openModalAjouterVue() {
      this.addvue = true;
      this.checkAll = true;
      this.vueToAdd.selected = this.checkAll
        ? this.getListeFields.slice()
        : this.getListeFields.filter(option => option.disabled).slice();
    },
    changeAllCheckColumns() {
      this.vueToAdd.selected = this.checkAll
        ? this.getListeFields.slice()
        : this.getListeFields.filter(option => option.disabled).slice();
    },
    async changeColumns() {
      const response = await this.updateNameVueDynamiqueAV(
        this.selectedVueDynamique
      );
    },
    hideModal(ref) {
      this[ref] = false;
      this.resetModal(ref);
      if (ref == 'addvue') {
        this.$refs.addvue.reset();
      }
      if (ref == 'UpdateNameVueDynamiqueModal') {
        this.$refs.UpdateNameVueDynamiqueModal.reset();
      }
    },
    resetModal(ref) {
      if (ref == 'addvue') {
        this.vueToAdd = {
          name: null,
          selected: fieldsAnalyseValidationModal.create().list
        };
        if (
          this.selectedVueDynamique &&
          this.selectedVueDynamiqueToDelete &&
          this.selectedVueDynamique.id == this.selectedVueDynamiqueToDelete.id
        ) {
          this.selectedVueDynamique = null;
        }
        this.selectedVueDynamiqueToDelete = null;
        this.selectedVueDynamiqueToUpdate = null;
      }
      this.loading = false;
      this.error = null;
    },
    async hendleSubmitVue() {
      if (this.$refs.addvue.validate()) {
        this.loading = true;
        this.error = null;
        if (this.vueToAdd.selected.length == 0) {
          this.loading = false;
          this.error = ['column:required'];
          return;
        }
        const formData = new FormData();
        formData.append('name', this.vueToAdd.name);
        for (let i = 0; i < this.vueToAdd.selected.length; i++) {
          formData.append(
            'columns[' + i + '][key]',
            this.vueToAdd.selected[i].key
          );
          formData.append(
            'columns[' + i + '][label]',
            this.vueToAdd.selected[i].label
          );
          if (this.vueToAdd.selected[i].thClass) {
            formData.append(
              'columns[' + i + '][thClass]',
              this.vueToAdd.selected[i].thClass
            );
          } else {
            formData.append('columns[' + i + '][thClass]', '');
          }
          if (this.vueToAdd.selected[i].tdClass) {
            formData.append(
              'columns[' + i + '][tdClass]',
              this.vueToAdd.selected[i].tdClass
            );
          } else {
            formData.append('columns[' + i + '][tdClass]', '');
          }
          formData.append(
            'columns[' + i + '][disabled]',
            this.vueToAdd.selected[i].disabled
          );
        }
        const response = await this.AddVueDynamiqueAV(formData);
        if (response.success) {
          this.loading = false;
          this.hideModal('addvue');
        } else {
          this.loading = false;
          this.error = response.error;
        }
      }
    },
    handleDeleteVueDynamique(vueDyn, edit) {
      if (edit) {
        this.edit = true;
      }
      this.selectedVueDynamiqueToDelete = vueDyn;
      this.VueDynamiqueDeleteModal = true;
    },
    async handleModalSupprimerValider() {
      if (this.edit) {
        const response = await this.blockVueDynamiqueAV(
          this.selectedVueDynamiqueToDelete
        );
        if (response) {
          this.hideModal('VueDynamiqueDeleteModal');
          this.edit = false;
        }
      } else {
        const response = await this.deleteVueDynamiqueAV(
          this.selectedVueDynamiqueToDelete.id
        );
        if (response) {
          this.hideModal('VueDynamiqueDeleteModal');
        }
      }
    },
    handleUpdateVueDynamique(vueDyn) {
      this.selectedVueDynamiqueToUpdate = { ...vueDyn };
      this.UpdateNameVueDynamiqueModal = true;
    },
    async submitUpdateNameVueDynamique() {
      if (this.$refs.UpdateNameVueDynamiqueModal.validate()) {
        this.loading = true;
        const response = await this.updateNameVueDynamiqueAV(
          this.selectedVueDynamiqueToUpdate
        );
        if (response) {
          this.loading = false;
          this.hideModal('UpdateNameVueDynamiqueModal');
        }
      }
    },
    handleClickVueDynamique(item) {
      this.selectedVueDynamique = item;
    }
  },
  computed: {
    ...mapGetters([
      'getVueDynamiqueAV',
      'getErrorVueDynamiqueAV',
      'getLoadingVueDynamiqueAV',
      'checkPermission'
    ])
  },
  mounted() {
    this.getListeFields = fieldsAnalyseValidationModal.create().list;
    this.vueToAdd.selected = fieldsAnalyseValidationModal.create().list;
    this.getAllVueDynamiqueAV();
  }
};
</script>

<style scoped lang="scss">
.vue-dynamique-class {
  .vue-body {
    margin-top: 10px;
    display: flex;
    width: 100%;
    height: calc(100vh - 228px);
    background-color: #f5f5f5c7;
    padding: 10px;
    // border-radius: 15px;
    .block-vues {
      width: 30%;
      padding: 5px 20px;
      background-color: #fff;
      // border-radius: 15px;

      .title {
        margin-bottom: 10px;
        margin-top: 10px;
        text-align: center;
        color: #2a2a2a;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 600;
      }
      .content-vue {
        overflow-y: auto;
        max-height: 100%;
        height: 85%;
      }
      .content-vue::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      .content-vue::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #f0eef8;
        border-radius: 10px;
      }
      .content-vue::-webkit-scrollbar-thumb {
        background: #704ad1;
        border-radius: 7px;
      }
      .body-vue-dyn-av {
        .custom-list-group-vue {
          overflow-y: auto;
          max-height: calc(100vh - 150px);
          height: calc(100vh - 150px);
          .list-group-vue .list-group-item {
          }
        }
      }
    }
    .block-vue-details {
      width: 70%;
      padding: 5px 20px;
      background-color: #fff;
      // border-radius: 15px;
      margin-left: 20px;
      overflow-y: auto;

      .custom-list-column-vu {
      }
    }
    .block-vue-details::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .block-vue-details::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 10px;
    }
    .block-vue-details::-webkit-scrollbar-thumb {
      background: #704ad1;
      border-radius: 7px;
    }
  }
}
.switch-vue {
  position: relative;
  width: 11%;
  margin: auto;
}
</style>
<style lang="scss">
.custom-class-fields-av,
.custom-class-fields-av-update {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow: hidden;
  text-align-last: left;
  max-height: calc(100% - 90px);
}
.custom-class-fields-av {
  .checked-vue {
    width: 20%;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    position: relative;
  }
}
.custom-class-fields-av-update {
  .checked-vue {
    width: 33%;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    position: relative;
  }
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
</style>
